import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateCluster } from '../../../api/mobileAppClientsApi';

function useUpdateCluster() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ clusterId, cluster }) => updateCluster(clusterId, cluster),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['mobile-clusters']);
      },
    }
  );
}

export default useUpdateCluster;
